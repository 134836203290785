import * as React from 'react';
import ImageLoading, { Fallback, LoadingPlaceholder } from 'react-image-loading';

const Img = (props) => (
    <ImageLoading>
        {(ref, status) => (
            <React.Fragment>
                {status === 'error' || !props.src
                    ? <Fallback style={{ backgroundColor: '#fafafa'}} />
                    : <React.Fragment>
                        <img ref={ref} {...props} alt="" />
                        <LoadingPlaceholder
                            style={{ 
                                transition: 'opacity 0.6s', 
                                opacity: status === 'loading' ? 1 : 0, 
                                backgroundColor: '#fafafa' 
                            }}
                            animate={status === 'loading'}
                        />
                    </React.Fragment>
                }
            </React.Fragment>
        )}
    </ImageLoading>
);

export default Img;